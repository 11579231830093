<template>
	<div class="person-container" ref="personCont">
		<div class="jessica-experience">
			<div class="jessica-picture-container">
				<img
					class="jessica-img"
					v-if="experience?.image"
					:src="imageUrlFor(experience.image.image)"
				/>
			</div>
			<h1 class="jessica-name" ref="title">{{ experience?.name }}</h1>
			<div v-if="experience && experience.testimony" class="testimony-wrapper">
				<h4 class="testimony">"{{ experience.testimony }}"</h4>
			</div>

			<div class="experience-1 paragraphs-wrapper" :class="{ 'experience-testimony': experience && experience.testimony }" ref="expOne" v-html="nl2br(experience?.firstParagraph)" />
			<div v-if="experience && experience.vimeoUrl" class="video">
				<iframe
					width="100%"
					height="100%"
					:src="experience?.vimeoUrl"
					frameborder="0"
					allow="autoplay; encrypted-media"
					allowfullscreen=""
				></iframe>
			</div>

			<div class="experience-2" ref="expTwo">
				<div class="paragraphs-wrapper" v-if="experience && experience.secondParagraph">
					<div v-html="nl2br(experience?.secondParagraph)" />
				</div>

				<div class="paragraphs-wrapper" v-if="experience && experience.thirdParagraph">
					<br />
					<br />
					<div v-html="nl2br(experience && experience.thirdParagraph)" />
				</div>

				<div class="paragraphs-wrapper" v-if="experience && experience.fourthParagraph">
					<br />
					<br />
					<div v-html="nl2br(experience?.fourthParagraph)" />
				</div>

				<div class="paragraphs-wrapper" v-if="experience && experience.authorNameLocation">
					<br />
					<br />
					<div class="authorName" v-html="nl2br(experience?.authorNameLocation)" />
				</div>

			</div>
			<div v-if="experience && experience.moreExperiences" class="other-experiences-container">
				<div
					v-for="(person, index) in experience?.moreExperiences"
					:key="index"
					:class="
						index === 0
							? 'experience-2-container'
							: 'experience-3-container'
					"
				>
					<h1 class="exp-name">{{ person?.name }}</h1>
					<router-link :to="person.slug.current">
						<button
							class="person-two-button person-button"
							style="text-transform: uppercase"
						>
							<h3>HEAR {{ person?.name }}'S STORY</h3>
						</button>
					</router-link>
					<div class="experience-2-img-container">
						<img
							v-if="person.image"
							:src="imageUrlFor(person.image.image)"
							alt=""
							class="experience-2-img"
						/>
					</div>
				</div>
			</div>
		</div>
        <WorkTogether />
	</div>
</template>

<script>
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { SplitText } from "gsap/dist/SplitText";
import utils from "../mixins/utils";
import WorkTogether from "../components/WorkTogether.vue";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
const imageBuilder = imageUrlBuilder(sanity);


const queryStories = `*[_type == "experience" && slug.current == $slug][0]{
    slug,
	...,
    moreExperiences[]->{_id, image, name, slug}
}`;

export default {
	name: "Stories",
	components: {
			WorkTogether
	},
	mixins: [utils],
	data: () => ({
		story: [],
		loading: true,
		error: ""
	}),
	mounted() {
		this.fetchStories();
	},
	computed: {
		experience() {
			return this.story;
		}
	},
	methods: {
		imageUrlFor(source) {
			return imageBuilder.image(source);
		},
		fetchStories() {
			this.error = this.story = null;
			this.loading = true;
			sanity.fetch(queryStories, { slug: this.$route.params.slug }).then(
				(story) => {
					this.loading = false;
					this.story = story;
					console.log(story)
				},
				(err) => {
					console.error(err);
				}
			);
		}
	},
	watch: {
		$route() {
			if (this.story.slug.current != this.$route.params.slug) {
				this.fetchStories();
			}
		}
	}
};
</script>

<style>
.jessica-experience .paragraphs-wrapper h2,
.jessica-experience .paragraphs-wrapper h3,
.authorName  {
	font-family: BonVivant !important;
	font-size: 2.1vw;
	line-height: 2.3vw;
	margin: 0;
}
@media (max-aspect-ratio: 200/200) {
	.jessica-experience .paragraphs-wrapper h2,
	.jessica-experience .paragraphs-wrapper h3,
	.authorName  {
		font-size: 6.5vw;
		line-height: 7.5vw;
	}
}
</style>

<style scoped>
.work-together-container {
	position: relative;
	background: none;
	top: 12.5vw;
}
.testimony-wrapper {
    position: relative;
    width: 44vw;
    font-size: 2vw;
    line-height: 2.5vw;
    font-weight: 400;
    text-align: left;
    margin: 12vw auto 0;
}
.experience-1.experience-testimony {
margin-top: 3vw;
}
.jessica-img {
	height: 100%;
    object-fit: cover;
}

h1 {
	text-transform: uppercase;
}

.video {
}



@media (max-aspect-ratio: 200/200) {
	.work-together-container {
		margin-top: 20vw;
	}
	.testimony-wrapper {
	width: 84vw;
	margin: 19vw auto 0 !important;
    font-size: 5vw;
    line-height: 5.5vw;
    margin-top: 4vw;
}
}
.person-container {
	width: 100vw;
}

</style>
